import { httpClient } from './axiosClients'

export function loadOrdersForTodo (todoId) {
  return httpClient.get(`/api/orders?todo_id=${todoId}`)
}

export function placeOrder (payload) {
  return httpClient.post('/api/orders', payload)
}

export function placeBulkOrder (payload) {
  return httpClient.post('/api/orders/bulk', payload)
}

export function resendOrder (orderId) {
  return httpClient.post(`/api/orders/${orderId}/resend`)
}

export function placeV2Order (payload) {
  return httpClient.post('/api/v2/orders', payload)
}

export function getOrdersForTodoV2 (todoId) {
  return httpClient.get(`/api/v2/orders?todo_id=${todoId}`)
}

export function getOwnerStatutesForAsbestos () {
  return httpClient.get('/api/v2/orders/asbestos-dossiers/owner-statutes')
}

export function getAsbestosDossiers (params) {
  return httpClient.get('/api/v2/orders/asbestos-dossiers', { params })
}

export function updateAsbestosDossier (dossierId, payload) {
  return httpClient.patch(`/api/v2/orders/asbestos-dossiers/${dossierId}`, payload)
}

export function getDossierMediaObjects (dossierId) {
  return httpClient.get(`/api/v2/orders/asbestos-dossiers/${dossierId}/media`)
}

export function createDossierMediaObject (dossierId, payload) {
  return httpClient.post(`/api/v2/orders/asbestos-dossiers/${dossierId}/media`, payload)
}

export function updateDossierMediaObjects (dossierId, payload) {
  return httpClient.patch(`/api/v2/orders/asbestos-dossiers/${dossierId}/media`, payload)
}

export function getDestinationTypes (params) {
  return httpClient.get('/api/v2/orders/asbestos-dossiers/destination-types', { params })
}

export function retryOrder (orderId) {
  return httpClient.post(`/api/v2/orders/${orderId}/retry`)
}

export function getAreasForDestructiveResearch () {
  return httpClient.get('/api/v2/orders/asbestos-dossiers/areas-for-destructive-research')
}

export function getAsbestosUpdateReasons () {
  return httpClient.get('/api/v2/orders/asbestos-dossiers/update-reasons')
}

export function getAsbestosCertificateReasons () {
  return httpClient.get('/api/v2/orders/asbestos-dossiers/certificate-reasons')
}

export function getAsbestosResearchReasons () {
  return httpClient.get('/api/v2/orders/asbestos-dossiers/additional-research-reasons')
}

export function validateAsbestosDossier (dossierId) {
  return httpClient.get(`/api/v2/orders/asbestos-dossiers/${dossierId}/validate`)
}

export function generateAsbestosDocument (dossierId) {
  return httpClient.post(`/api/v2/orders/asbestos-dossiers/${dossierId}/generate`)
}

export function createAsbestosAssignment (payload) {
  return httpClient.post('/api/v2/orders/asbestos-dossiers/assignments', payload)
}

export function storeAsbestosFormFile (dossierId, payload) {
  // Used for storing the manually signed dossier form
  return httpClient.post(`api/v2/orders/asbestos-dossiers/${dossierId}/store-form-file`, payload)
}

export function resetAsbestosDossier (dossierId) {
  return httpClient.post(`/api/v2/orders/asbestos-dossiers/${dossierId}/reset`)
}

export function pollSignatureStatus (dossierId) {
  return httpClient.get(`/api/v2/orders/asbestos-dossiers/${dossierId}/signature`)
}

export function getExistingBaseRegistersData (params) {
  return httpClient.get('/api/v2/orders/vip/existing-base-registers-data', { params })
}

export function getAsbestosDossierHistory (dossierId) {
  return httpClient.get(`/api/v2/orders/asbestos-dossiers/${dossierId}/history`)
}

export function getSupplierProducts (params) {
  return httpClient.get('/api/supplier-products', { params })
}

export function checkRealSmartDossierIsManual (propertyId) {
  return httpClient.get(`/api/v2/orders/manual-realsmart-dossier-check/${propertyId}`)
}
